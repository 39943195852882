@charset "UTF-8";
html {
  font-size: calc(10em / 16);
  color: #2A2A2B;
  -webkit-font-feature-settings: "palt" 1;
  font-feature-settings: "palt" 1;
  letter-spacing: 0.1em; }

body {
  font-family: "游ゴシック",Yu Gothic,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Osaka,sans-serif; }

main {
  background-image: url(/img/common/bg.jpg); }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

ul {
  list-style: none; }

a {
  text-decoration: none; }

figure {
  margin: 0; }

picture {
  display: block; }

.mod-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #CB3A3E;
  padding: 1.8rem 3.5rem;
  color: #CB3A3E;
  background-color: #FFF;
  font-size: 1.5rem;
  font-family: "Noto Serif JP",serif;
  position: relative; }

.mod-btn:after {
  content: "";
  display: block;
  width: 2.6rem;
  height: 0.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(/img/common/arrow-tochange.svg);
  transform: translateY(-10%);
  margin-left: 1rem; }

.mod-btn[target=_blank]:before, .mod-btn[target=_blank]:after {
  content: "";
  width: 1.3rem;
  height: 1rem;
  background-color: transparent;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 3rem; }

.mod-btn[target=_blank]:before {
  border: 1px solid #CB3A3E;
  margin: -0.1rem -0.1rem 0 0;
  transform: translateY(-50%); }

.mod-btn[target=_blank]:after {
  border-bottom: 1px solid #CB3A3E;
  border-left: 1px solid #CB3A3E;
  transform: translate(-0.2rem, calc(-50% + 0.2rem));
  background-image: none; }

.mod-btn--line {
  border-right: none;
  border-left: none; }

.mod-btn--more {
  padding: 1.8rem 3.5rem 1.7rem 2rem; }

.mod-btn--more:before, .mod-btn--more:after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1px;
  background-color: #CB3A3E;
  position: absolute;
  top: 50%;
  right: 1.8rem; }

.mod-btn--more:before {
  transform: translateY(-50%); }

.mod-btn--more:after {
  transform: translateY(-50%) rotate(90deg); }

.mod-btn--justify {
  justify-content: space-between; }

.mod-btn--toback:after {
  content: none; }

.mod-btn--toback:before {
  content: "";
  display: block;
  width: 2.6rem;
  height: 0.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(/img/common/arrow-tochange.svg);
  transform: translateY(-10%) scaleX(-1);
  margin-right: 1rem; }

@media screen and (max-width: 560px) {
  .mod-btn {
    padding: 1.2rem 1.5rem;
    letter-spacing: 0; } }

main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0; }

.message {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  font-size: 1rem;
  text-align: center; }

.message__title {
  color: #CB3A3E;
  font-size: 2.8rem;
  line-height: 1.3;
  font-family: "Noto Serif JP",serif;
  font-weight: normal;
  margin-bottom: 1rem; }

.message__lead {
  font-size: 1.7rem;
  line-height: 1.3;
  margin-bottom: 1.2rem; }

.message__cap {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 3rem; }

.message > .mod-btn {
  margin: 0 auto;
  max-width: 33rem; }

@media screen and (max-width: 560px) {
  .message {
    width: 85%;
    text-align: left; }
  .message > .mod-btn {
    max-width: inherit; } }
